import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Radio,
  Stack,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { Question } from './type';
import { RenderHTMLContent, RenderHTMLContent2 } from './extractQuestions';
import { green, pink } from '@mui/material/colors';
import ImageBox from './ImageBox';

// Props for the QuestionPart component
interface QuestionPartProps {
  partLabel: string; // 'a', 'b', 'c', 'd'
  questionContent: string;
  value: string | null;
  onChange: (newValue: string | null) => void;
  formatSize?: number;
  questionImageUrl?: string;
}

const QuestionPart: React.FC<QuestionPartProps> = ({
  partLabel,
  questionContent,
  questionImageUrl,
  value,
  onChange,
  formatSize = 1,
}) => {
  return (
    <Box
      mb={0.5}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box
        sx={{
          position: 'relative',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Typography variant="examText">
          {/* <strong>{partLabel})</strong> {RenderHTMLContent2(questionContent)} */}
          {RenderHTMLContent2(questionContent, partLabel + ')')}
        </Typography>
        {questionImageUrl && (
          // <Box
          //   component="img"
          //   src={questionImageUrl}
          //   alt={`Question ${questionImageUrl}`}
          //   onLoad={handleImageLoad}
          //   sx={{
          //     float: 'right',
          //     marginLeft: 2,
          //     marginBottom: 2,
          //     width: imageSize.width * formatSize + 'px',
          //     height: 'auto',
          //   }}
          // />
          <ImageBox
            src={questionImageUrl}
            alt={`Question ${questionImageUrl}`}
            formatSize={formatSize}
          />
        )}
      </Box>

      <FormControl
        sx={{
          minWidth: 'auto',
        }}
      >
        <RadioGroup
          row
          aria-label={`radio-${partLabel}`}
          name={`radio-${partLabel}`}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}
        >
          <FormControlLabel
            value="1"
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: formatSize + 0.5 + 'rem',
                  },
                  color: green[800],
                  '&.Mui-checked': {
                    color: green[600],
                  },
                  p: 0,
                }}
              />
            }
            label={<></>}
            sx={{ py: formatSize - 0.5, mx: 0 }}
            labelPlacement="start"
          />
          <FormControlLabel
            value="0"
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: formatSize + 0.5 + 'rem',
                  },
                  color: pink[800],
                  '&.Mui-checked': {
                    color: pink[600],
                  },
                  pr: 0,
                }}
              />
            }
            label={<></>}
            sx={{ py: formatSize - 0.5, mx: 0 }}
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>

      {/* <Stack direction="row">
        <Radio
          checked={value === '1'}
          onChange={() => onChange('1')}
          value="1"
          name={`radio-${partLabel}`}
          inputProps={{ 'aria-label': '1' }}
          
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: formatSize + 0.5 + 'rem',
            },
          }}
        />
        <Radio
          checked={value === '0'}
          onChange={() => onChange('0')}
          value="0"
          name={`radio-${partLabel}`}
          inputProps={{ 'aria-label': '0' }}
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: formatSize + 0.5 + 'rem',
            },
          }}
        />
      </Stack> */}
    </Box>
  );
};

// Props for the MCTFQuestion component
interface MCTFQuestionProps {
  question: Question;
  editable?: boolean;
  onAnswerChange?: (answer: string) => void;
  answer?: string;
  formatSize?: number;
  checkCorrect?: boolean;
}

const MCTFQuestion: React.FC<MCTFQuestionProps> = ({
  question,
  editable,
  onAnswerChange,
  answer,
  formatSize = 1,
  checkCorrect = false,
}) => {
  const [answers, setAnswers] = useState<{
    A: string | null;
    B: string | null;
    C: string | null;
    D: string | null;
  }>({
    A: null,
    B: null,
    C: null,
    D: null,
  });

  // Initialize answers based on the question's correct answers
  useEffect(() => {
    if (answer !== undefined) {
      setAnswers({
        A: answer?.charAt(0) || null,
        B: answer?.charAt(1) || null,
        C: answer?.charAt(2) || null,
        D: answer?.charAt(3) || null,
      });
    } else {
      setAnswers({
        A: question.correctAnswer[0], // Assuming correctAnswer is a string like '1001'
        B: question.correctAnswer[1],
        C: question.correctAnswer[2],
        D: question.correctAnswer[3],
      });
    }
  }, [question.correctAnswer, editable, answer]);

  const handleAnswerChange = (
    questionPart: 'A' | 'B' | 'C' | 'D',
    newAnswer: string | null
  ) => {
    if (checkCorrect) return;
    if (!editable) return;

    const newAnswers = { ...answers, [questionPart]: newAnswer };

    setAnswers(newAnswers);

    onAnswerChange &&
      onAnswerChange(
        `${newAnswers.A || '_'}${newAnswers.B || '_'}${newAnswers.C || '_'}${
          newAnswers.D || '_'
        }`
      ); // Convert the answers to a string
  };

  return (
    <Box>
      {/* Render sub-question A */}
      <QuestionPart
        partLabel="a"
        questionContent={question.questionA}
        value={answers.A}
        onChange={(newValue) => handleAnswerChange('A', newValue)}
        formatSize={formatSize}
        questionImageUrl={question.questionAImageUrl}
      />

      {/* Render sub-question B */}
      <QuestionPart
        partLabel="b"
        questionContent={question.questionB}
        value={answers.B}
        onChange={(newValue) => handleAnswerChange('B', newValue)}
        formatSize={formatSize}
        questionImageUrl={question.questionBImageUrl}
      />

      {/* Render sub-question C */}
      {question.questionC && (
        <QuestionPart
          partLabel="c"
          questionContent={question.questionC}
          value={answers.C}
          onChange={(newValue) => handleAnswerChange('C', newValue)}
          formatSize={formatSize}
          questionImageUrl={question.questionCImageUrl}
        />
      )}

      {/* Render sub-question D */}
      {question.questionD && (
        <QuestionPart
          partLabel="d"
          questionContent={question.questionD}
          value={answers.D}
          onChange={(newValue) => handleAnswerChange('D', newValue)}
          formatSize={formatSize}
          questionImageUrl={question.questionDImageUrl}
        />
      )}
    </Box>
  );
};

export default MCTFQuestion;
