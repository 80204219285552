import React, { useState } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

interface ImageBoxProps {
  src: string;
  alt: string;
  formatSize: number;
  sx?: SxProps<Theme>;
}

const ImageBox: React.FC<ImageBoxProps> = ({ src, alt, formatSize, sx }) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalWidth, naturalHeight } = e.currentTarget;

    setImageSize({
      width: naturalWidth * 0.5, //* 0.5 //* 0.75
      height: naturalHeight * 0.5, //* 0.75
    });
  };

  return (
    <Box
      component="img"
      src={src}
      alt={alt}
      onLoad={handleImageLoad}
      sx={{
        float: 'right',
        marginLeft: 2,
        marginBottom: 2,
        width: imageSize.width * formatSize + 'px',
        height: 'auto',
        maxWidth: '100%', //isMobile ? '100%' : '75%',
        ...sx,
      }}
    />
  );
};

export default ImageBox;

// import React, { useState, useRef, useEffect } from 'react';
// import { Box, SxProps, Theme } from '@mui/material';

// interface ImageBoxProps {
//   src: string;
//   alt: string;
//   formatSize: number;
//   sx?: SxProps<Theme>;
// }

// const ImageBox: React.FC<ImageBoxProps> = ({ src, alt, formatSize, sx }) => {
//   const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
//   const parentRef = useRef<HTMLDivElement>(null); // Reference to parent container

//   const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
//     const { naturalWidth, naturalHeight } = e.currentTarget;

//     const parentWidth = parentRef.current?.offsetWidth || 0;

//     console.log('Parent width:', parentWidth);

//     // Calculate adjusted width
//     let adjustedWidth = naturalWidth * formatSize;
//     if (adjustedWidth > 0.75 * parentWidth) {
//       adjustedWidth = parentWidth; // Set to 100% if exceeding 75% of parent
//     }

//     setImageSize({
//       width: adjustedWidth,
//       height: (adjustedWidth / naturalWidth) * naturalHeight, // Maintain aspect ratio
//     });
//   };

//   return (
//     <Box ref={parentRef} sx={{ width: '100%', position: 'relative' }}>
//       <Box
//         component="img"
//         src={src}
//         alt={alt}
//         onLoad={handleImageLoad}
//         sx={{
//           float: 'right',
//           marginLeft: 2,
//           marginBottom: 2,
//           width: `${imageSize.width}px`,
//           height: `${imageSize.height}px`,
//           maxWidth: '100%',
//           ...sx,
//         }}
//       />
//     </Box>
//   );
// };

// export default ImageBox;
