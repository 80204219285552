import React from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Stack,
  IconButton,
  ListItemIcon,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PeopleIcon from '@mui/icons-material/People';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { vi } from 'date-fns/locale';
import { useDispatch } from 'react-redux';
import InfoItem from '../Courses/CourseInfoItem';
import { Exam, ExamComponentProps } from '../Exams/type';
import { translateSubject } from '../Exams/docUtils';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const ExamCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition for transform and shadow
  '&:hover, &:focus-within': {
    transform: 'translateY(-10px)', // Move up by 10px
  },
}));

const ExamTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  display: '-webkit-box', // Required for limiting lines of text
  WebkitLineClamp: 1, // Limits to 2 lines; you can adjust this number
  WebkitBoxOrient: 'vertical', // Required for multiline ellipsis
  overflow: 'hidden', // Hides the overflowing text
  textOverflow: 'ellipsis', // Adds '...' at the end of the truncated text
  height: 'auto', // Ensures the height adapts to content
}));

export const WrapText = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  WebkitLineClamp: 2, // Limits to 2 lines
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  height: 'auto',
}));

export const ExamStats = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
}));

export const ListItemIconCustom = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 'small',
}));

export const AdminActions = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(1),
}));

export const CircleButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  borderRadius: '50%', // Làm nút tròn
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const ExamComponent: React.FC<ExamComponentProps> = ({
  exam,
  handleOpenDialog,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    // navigate(`/examming/${examId}`);
    handleOpenDialog(exam.examId);
  };

  // const getImage = (subject: string) => {
  //   if (subject === 'Math') {
  //     return 'https://res.cloudinary.com/dmpprfvzq/image/upload/v1729677192/78d54000-4956-418f-a653-8fda4af58263.png';
  //   } else if (subject === 'Physic') {
  //     return 'https://res.cloudinary.com/dmpprfvzq/image/upload/v1729677347/f2910323-2f7a-4086-b198-fae302bb66db.png';
  //   } else if (subject === 'Chemistry') {
  //     return 'https://res.cloudinary.com/dmpprfvzq/image/upload/v1729677406/6615d8da-2acc-4cfb-8a86-d1d2503b44cc.png';
  //   }
  // };

  return (
    <ExamCard
      onClick={handleClick}
      elevation={0}
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: 200,
        maxWidth: 280,
        // width: 200,
        minHeight: 200,
        backgroundColor: 'background.paper',
        mr: 2,
      }}
    >
      <Box>
        <ExamTitle variant="subtitle1" sx={{ color: 'text.secondary' }}>
          {translateSubject(exam.subject)}
          {exam.parentFolderName ? ` - ${exam.parentFolderName}` : ''}{' '}
        </ExamTitle>
        {/* <ExamTitle variant="subtitle1" sx={{ color: 'text.secondary' }}>
          {course?.courseId} {parentFolderName ? ` / ${parentFolderName}` : ''}{' '}
        </ExamTitle> */}
      </Box>

      {/* Centered text over the image */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="h4"
          maxWidth={'100%'}
          style={{ display: 'inline-block', whiteSpace: 'pre-line' }}
          textAlign={'center'}
        >
          {exam.title}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          {!exam.isExercise && exam.executedAt && (
            <>
              {(() => {
                const now = new Date();
                const executedAtTime = new Date(exam.executedAt);
                const endAt = new Date(
                  executedAtTime.getTime() + exam.duration * 60000
                ); // item.duration in minutes

                if (now > endAt) {
                  return (
                    <Typography
                      variant="caption"
                      fontWeight={600}
                      sx={{
                        color: 'error.main',
                        maxWidth: 150,
                        display: 'inline-block',
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                      }}
                    >
                      {' '}
                      Đã kết thúc
                    </Typography>
                  );
                } else if (now < executedAtTime) {
                  return (
                    <Typography
                      variant="caption"
                      fontWeight={600}
                      sx={{
                        color: 'success.main',
                        maxWidth: 150,
                        display: 'inline-block',
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                      }}
                    >
                      Diễn ra sau{' '}
                      {formatDistanceToNow(executedAtTime, {
                        addSuffix: true,
                        locale: vi,
                      })}
                    </Typography>
                  );
                } else {
                  return (
                    <Typography
                      variant="caption"
                      fontWeight={600}
                      sx={{
                        color: 'success.main',
                        maxWidth: 150,
                        display: 'inline-block',
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                      }}
                    >
                      {' '}
                      Đang diễn ra
                    </Typography>
                  );
                }
              })()}
            </>
          )}
        </Box>
        <InfoItem
          sx={{
            marginBottom: 0,
            color: 'text.secondary',
            mr: 0.5,
          }}
          variant="subtitle1"
          icon={<PeopleIcon sx={{ fontSize: 18, color: 'text.secondary' }} />}
          value={exam.examTimes}
          ml={0.5}
        />
      </Box>

      <Stack direction="row" spacing={1}>
        <img
          src={exam.course?.createdBy.avatar}
          alt="avatar"
          style={{ height: 20, width: 20, borderRadius: '50%' }}
          referrerPolicy="no-referrer"
        />

        <Stack direction="column" spacing={0}>
          <ExamStats>
            <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
              {exam.course?.createdBy.name}
            </Typography>
          </ExamStats>
          <ExamStats>
            <Stack direction="row" spacing={0}></Stack>
          </ExamStats>
        </Stack>
      </Stack>
    </ExamCard>
  );
};

export default ExamComponent;
