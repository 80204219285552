import React from 'react';
import { Box, Stack, Button, Typography } from '@mui/material';
import AddButton from '../Courses/AddButton';
import { translateSubject } from '../Exams/docUtils';

interface CourseHeaderProps {
  onAddCourseClick: () => void;
  onFilterChange: (filter: string) => void;
  isTeacher: boolean;
  filter: string;
  isHomePage: boolean;
}

interface FilterButtonProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({
  label,
  isActive,
  onClick,
}) => (
  <Button
    onClick={onClick}
    variant="contained"
    sx={{
      backgroundColor: isActive ? 'primary.main' : 'background.paper',
      '&:hover': {
        backgroundColor: isActive ? 'primary.dark' : 'primary.light',
      },
      color: isActive ? '' : 'text.primary',
      px: 1.5,
      minWidth: 'auto',
      textTransform: 'none',
    }}
  >
    <Typography variant="subtitle2" fontSize="small">
      {label}
    </Typography>
  </Button>
);

const CourseHeader: React.FC<CourseHeaderProps> = ({
  onAddCourseClick,
  onFilterChange,
  isTeacher,
  filter,
  isHomePage,
}) => {
  const filterOptions = [
    { label: 'Tất cả', value: 'all' },
    { label: translateSubject('Math'), value: 'Math' },
    { label: translateSubject('Physic'), value: 'Physic' },
    // { label: translateSubject('Chemical'), value: 'Chemical' },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mb: 2,
      }}
    >
      {/* Left - Filter Buttons */}
      {isHomePage && (
        <Stack direction="row" spacing={1}>
          {filterOptions.map(({ label, value }) => (
            <FilterButton
              key={value}
              label={label}
              isActive={filter === value}
              onClick={() => onFilterChange(value)}
            />
          ))}
        </Stack>
      )}

      {isTeacher && !isHomePage && (
        <>
          <Typography variant="h3" textAlign="center">
            Khoá học của tôi
          </Typography>

          <Box ml={3}>
            <AddButton onClick={onAddCourseClick} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default CourseHeader;
