import { FC, useEffect, useState } from 'react';
import MePage from './MePage';
import HomePage from '.';
import { useLocation, useParams } from 'react-router-dom';
import paths from '../../constants/paths';
import { getOneStudent } from '../../api';
import { StudentInfo } from '../../model/Student';

const ProfilePage: FC = () => {
  const location = useLocation();
  const { userId } = useParams();

  const [profileUser, setProfileUser] = useState<StudentInfo | null>(null);

  useEffect(() => {
    const getOneUser = async () => {
      if (!userId) return;
      const res = await getOneStudent(userId);
      setProfileUser(res.data);
    };
    getOneUser();
  }, [userId]);
  return (
    <>
      <MePage userInput={profileUser} />

      {location.pathname !== paths.HOME && profileUser && (
        <HomePage userInput={profileUser} />
      )}
    </>
  );
};

export default ProfilePage;
