import React, { useEffect } from 'react';
import {
  Typography,
  TextField,
  Box,
  List,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Checkbox,
  Button,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SubjectIcon from '@mui/icons-material/Subject';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InfoIcon from '@mui/icons-material/Info';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import LinkIcon from '@mui/icons-material/Link';
import InfoItem from '../Courses/CourseInfoItem';
import dayjs from 'dayjs';
import useAuth from '../../hooks/useAuth';
import { Exam, ExamInput, VideoLink } from './type';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { translateSubject } from './docUtils';
import { Delete } from '@mui/icons-material';
import { formatVideoUrl } from './extractQuestions';

interface ExamDetailsProps {
  exam: Exam; // replace with your actual exam type
  mode?: 'view' | 'edit' | 'create';
  examInput?: ExamInput;
  setExamInput?: React.Dispatch<React.SetStateAction<ExamInput>>;
  isNotOnDialog?: boolean;
  showResultByTime: boolean;
}

const ExamDetails: React.FC<ExamDetailsProps> = ({
  exam,
  mode = 'view',
  examInput,
  setExamInput,
  isNotOnDialog = false,
  showResultByTime,
}) => {
  const { user } = useAuth();
  const [listLinks, setListLinks] = React.useState<VideoLink[]>(
    exam.listLinks || ['']
  );

  //Modify examInput every time listLinks changes
  useEffect(() => {
    setExamInput &&
      setExamInput((prevState) => ({
        ...prevState,
        listLinks: listLinks,
      }));
  }, [listLinks, setExamInput]);

  // useEffect(() => {
  //   if (listLinks.length === 0 && (mode === 'edit' || mode === 'create')) {
  //     setListLinks(exam.listLinks || ['']);
  //   }
  // }, [examInput, mode, exam.listLinks, listLinks]);

  const CeilingUpTime = (newTime: any) => {
    // Round up the minute to 5
    const roundedTime = dayjs(newTime)
      .minute(Math.ceil(newTime.minute() / 1) * 1)
      .second(0)
      .millisecond(0);

    return roundedTime;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    if (name === 'questionURL' && files && files.length > 0) {
      const file = files[0];
      const fileURL = URL.createObjectURL(file);
      setExamInput &&
        setExamInput((prevState) => ({
          ...prevState,
          questionURL: fileURL,
          questionPdf: file,
        }));
    } else {
      setExamInput &&
        setExamInput((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    }
  };

  const toggleExecutedAt = () => {
    setExamInput &&
      setExamInput((prevState) => ({
        ...prevState,
        executedAt: prevState.executedAt ? null : new Date(),
      }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Thông tin đề
      </Typography>
      {(user?.role === 'teacher' || user?.role === 'admin') &&
      (mode === 'edit' || mode === 'create') &&
      examInput ? (
        <>
          <TextField
            sx={{ marginTop: 0 }}
            label="Title"
            name="title"
            value={examInput.title}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="standard"
            InputProps={{
              startAdornment: <InfoOutlinedIcon style={{ marginRight: 8 }} />,
            }}
          />
          <TextField
            label="Duration (minutes)"
            name="duration"
            value={examInput.duration}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="standard"
            InputProps={{
              startAdornment: <AccessTimeIcon style={{ marginRight: 8 }} />,
            }}
          />
          <FormControl fullWidth margin="normal" variant="standard">
            <InputLabel>Subject</InputLabel>
            <Select
              name="subject"
              value={examInput.subject}
              onChange={(e) => {
                setExamInput &&
                  setExamInput((prevState) => ({
                    ...prevState,
                    subject: e.target.value,
                  }));
              }}
              startAdornment={<SubjectIcon style={{ marginRight: 8 }} />}
            >
              <MenuItem value="Math">Toán</MenuItem>
              <MenuItem value="Physic">Lý</MenuItem>
              <MenuItem value="Chemical">Hoá</MenuItem>
            </Select>
          </FormControl>

          <FormControl component="fieldset" margin="normal">
            <Typography
              variant="subtitle2"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              LOẠI
              <Tooltip
                title={
                  <>
                    <Typography variant="body2">
                      <strong>"Kiểm tra"</strong>: Mở đề vào thời gian xác định,
                      chỉ được làm một lần duy nhất
                    </Typography>
                    <Typography variant="body2">
                      <strong>"Bài tập"</strong>: Có thể làm lại nhiều lần bất
                      cứ khi nào
                    </Typography>
                  </>
                }
                arrow
              >
                <HelpOutlineIcon
                  style={{ marginLeft: 5, cursor: 'pointer', fontSize: 16 }}
                />
              </Tooltip>
            </Typography>
            <RadioGroup
              row
              name="isExercise"
              value={examInput.isExercise ? 'exercise' : 'test'}
              onChange={(e) => {
                const isExercise = e.target.value === 'exercise';
                setExamInput &&
                  setExamInput((prevState) => ({
                    ...prevState,
                    isExercise,
                    // executedAt: isExercise ? null : prevState.executedAt,
                  }));
              }}
            >
              <FormControlLabel
                value="test"
                control={<Radio size="small" sx={{ px: 1, pr: 0.5 }} />}
                label={<span style={{ fontSize: '0.9rem' }}>Kiểm tra</span>} // Smaller font size
              />
              <FormControlLabel
                value="exercise"
                control={<Radio size="small" sx={{ px: 1, pr: 0.5 }} />}
                label={<span style={{ fontSize: '0.9rem' }}>Bài tập</span>} // Smaller font size
              />
            </RadioGroup>
          </FormControl>

          <DatePicker
            slotProps={{ textField: { variant: 'standard' } }}
            sx={{
              display: examInput.isExercise ? 'none' : 'block',
            }}
            label="Executed Date"
            value={dayjs(examInput.executedAt)}
            onChange={(newDate) =>
              setExamInput &&
              setExamInput((prevState) => ({
                ...prevState,
                executedAt: newDate ? newDate.toDate() : null,
              }))
            }
          />
          <TimePicker
            slotProps={{ textField: { variant: 'standard' } }}
            sx={{
              marginTop: 3,
              mb: 2,
              display: examInput.isExercise ? 'none' : 'block',
            }}
            label="Executed Time"
            value={CeilingUpTime(dayjs(examInput.executedAt))}
            onChange={(newTime) =>
              setExamInput &&
              setExamInput((prevState) => ({
                ...prevState,
                executedAt: newTime ? newTime.toDate() : null,
              }))
            }
            // onChange={(newTime) => {
            //   if (newTime) {
            //     const roundedTime = dayjs(newTime)
            //       .minute(0)
            //       .second(0)
            //       .millisecond(0);

            //     // If the minute is not exactly 0, round up the hour
            //     if (newTime.minute() > 0) {
            //       setExamInput((prevState) => ({
            //         ...prevState,
            //         executedAt: roundedTime.add(1, 'hour').toDate(),
            //       }));
            //     } else {
            //       setExamInput((prevState) => ({
            //         ...prevState,
            //         executedAt: roundedTime.toDate(),
            //       }));
            //     }
            //   } else {
            //     setExamInput((prevState) => ({
            //       ...prevState,
            //       executedAt: null,
            //     }));
            //   }
            // }}
            ampm={false}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={examInput.onlyForMembers}
                onChange={(e) =>
                  setExamInput &&
                  setExamInput((prevState) => ({
                    ...prevState,
                    onlyForMembers: e.target.checked,
                  }))
                }
              />
            }
            label={
              <Typography sx={{ fontSize: '14px' }}>
                {' '}
                Chỉ dành cho thành viên khoá
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={examInput.cannotViewAnswerBeforeFirstExam}
                onChange={(e) =>
                  setExamInput &&
                  setExamInput((prevState) => ({
                    ...prevState,
                    cannotViewAnswerBeforeFirstExam: e.target.checked,
                  }))
                }
              />
            }
            label={
              <Typography sx={{ fontSize: '14px' }}>
                {' '}
                Chỉ được phép xem đáp án sau khi làm đề lần đầu
              </Typography>
            }
          />
          <TextField
            label="PDF Đề"
            name="pdfLink"
            value={examInput.pdfLink}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="standard"
            InputProps={{
              startAdornment: <LinkIcon style={{ marginRight: 8 }} />,
            }}
          />
          <TextField
            label="PDF Đáp án"
            name="pdfAnswerLink"
            value={examInput.pdfAnswerLink}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="standard"
            InputProps={{
              startAdornment: <LinkIcon style={{ marginRight: 8 }} />,
            }}
          />
          {listLinks.map((link, index) => (
            <Box key={index} display={'flex'} flexDirection={'row'}>
              <TextField
                label={`Title ${index + 1}`}
                name={`title${index}`}
                value={link.title}
                onChange={(e) => {
                  const newLinks = [...listLinks];
                  newLinks[index].title = e.target.value;
                  setListLinks(newLinks);
                }}
                fullWidth
                margin="normal"
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InfoOutlinedIcon style={{ marginRight: 8 }} />
                  ),
                }}
              />

              <TextField
                label={`Link ${index + 1}`}
                name={`link${index}`}
                value={link.link}
                onChange={(e) => {
                  const newLinks = [...listLinks];
                  newLinks[index].link = formatVideoUrl(e.target.value);
                  setListLinks(newLinks);
                }}
                fullWidth
                margin="normal"
                variant="standard"
                // InputProps={{
                //   startAdornment: <LinkIcon style={{ marginRight: 8 }} />,
                // }}
                // Add delete button to remove the link
                InputProps={{
                  startAdornment: <LinkIcon style={{ marginRight: 8 }} />,
                  endAdornment: (
                    <Delete
                      fontSize="small"
                      style={{ cursor: 'pointer', opacity: 0.5 }}
                      onClick={() => {
                        const newLinks = [...listLinks];
                        newLinks.splice(index, 1);
                        setListLinks(newLinks);
                      }}
                    />
                  ),
                }}
              />
            </Box>
          ))}
          {/* Add button to add new link to list links */}
          <Button
            variant="outlined"
            onClick={() =>
              setListLinks([...listLinks, { title: '', link: '' }])
            }
          >
            Add new video
          </Button>
        </>
      ) : (
        <Box>
          <List sx={{ py: 0 }}>
            <InfoItem
              icon={<InfoIcon sx={{ fontSize: 18 }} />}
              value={exam.title}
              isWrap={false}
            />
            <InfoItem
              icon={<InfoIcon sx={{ fontSize: 18 }} />}
              value={'ID: ' + exam.examId}
              isWrap={false}
            />
            <InfoItem
              icon={<AccessTimeIcon sx={{ fontSize: 18 }} />}
              value={`${exam.duration} phút`}
            />
            <InfoItem
              icon={<SubjectIcon sx={{ fontSize: 18 }} />}
              value={`Môn ${translateSubject(exam.subject)}`}
            />

            {exam.executedAt && !exam.isExercise && (
              <InfoItem
                icon={<CalendarTodayIcon sx={{ fontSize: 18 }} />}
                value={
                  dayjs(exam.executedAt || '').format('D/M/YYYY') +
                  ' - ' +
                  // exam.executedAt.toLocaleTimeString([], {
                  //   hour: '2-digit',
                  //   minute: '2-digit',
                  //   hour12: false,
                  // })
                  dayjs(exam.executedAt || '').format('HH:mm')
                }
              />
            )}
            {/* {!isNotOnDialog ? (
              <InfoItem
                icon={<LinkIcon fontSize="small" />}
                value="Link làm đề"
                URL={true}
                href={`/examming/${exam.examId}`}
              />
            ) : (
              <InfoItem
                icon={<LinkIcon fontSize="small" />}
                value="Link khoá học"
                URL={true}
                href={`/course/${exam.courseId}`}
              />
            )} */}
            {showResultByTime && (
              <>
                {exam.pdfLink && (
                  <InfoItem
                    icon={<LinkIcon fontSize="small" />}
                    value="PDF Đề"
                    URL={true}
                    href={exam.pdfLink}
                  />
                )}
                {exam.pdfAnswerLink && (
                  <InfoItem
                    icon={<LinkIcon fontSize="small" />}
                    value="PDF Đáp án"
                    URL={true}
                    href={exam.pdfAnswerLink}
                  />
                )}

                {exam.listLinks &&
                  exam.listLinks.map((link, index) => (
                    <InfoItem
                      key={index}
                      icon={<LinkIcon fontSize="small" />}
                      value={link.title}
                      URL={true}
                      href={link.link}
                    />
                  ))}
              </>
            )}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default ExamDetails;
