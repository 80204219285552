import React, { useEffect, useState } from 'react';
import { Box, Typography, alpha, useTheme } from '@mui/material';
import MCQQuestion from './MCQuestion';
import MCTFQuestion from './MCTFQuestion';
import SAQuestion from './SAQuestion';
import { Question } from './type';
import { RenderHTMLContent, RenderHTMLContent2 } from './extractQuestions';
import MainButton from '../Courses/MainButton';
import ImageBox from './ImageBox';
import useResponsive from '../../hooks/useResponsive';

// Props for the Question component
interface QuestionProps {
  question: Question;
  editable?: boolean;
  onAnswerChange?: (answer: string) => void;
  answer?: string;
  formatSize?: number;
  checkCorrect?: boolean;
  // onSAQuestionCheck?: (check: boolean) => void;
  // showCheckButton?: boolean;
}

const QuestionComponent: React.FC<QuestionProps> = ({
  question,
  editable,
  onAnswerChange,
  answer,
  formatSize = 1.2,
  checkCorrect = false,
  // onSAQuestionCheck,
  // showCheckButton = false,
}) => {
  const theme = useTheme();
  const isCorrect = question.correctAnswer === answer;
  const isMobile = useResponsive('down', 'md');

  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalWidth, naturalHeight } = e.currentTarget;
    setImageSize({
      width: naturalWidth * 0.5,
      height: naturalHeight * 0.5,
    });
  };

  // Conditional rendering based on question type
  const renderQuestionType = () => {
    switch (question.type) {
      case 'mcq':
        return (
          <MCQQuestion
            question={question}
            editable={editable}
            onAnswerChange={onAnswerChange}
            answer={answer}
            formatSize={formatSize}
            checkCorrect={checkCorrect}
          />
        );
      case 'mctf':
        return (
          <MCTFQuestion
            question={question}
            editable={editable}
            onAnswerChange={onAnswerChange}
            answer={answer}
            formatSize={formatSize}
            checkCorrect={checkCorrect}
          />
        );
      case 'sa':
        return (
          <SAQuestion
            question={question}
            editable={editable}
            onAnswerChange={onAnswerChange}
            answer={answer}
            formatSize={formatSize}
            checkCorrect={checkCorrect}
          />
        );
      default:
        return <Typography variant="body1">Unknown question type</Typography>;
    }
  };

  return (
    <Box
      sx={{
        px: 1,
        maxWidth: '100%',
        fontSize: `${formatSize}rem`,
      }}
    >
      {question.index === 1 && (
        <>
          <Typography variant="examText" gutterBottom>
            <strong>{question.section}</strong>
            {RenderHTMLContent(question.sectionContent || '')}
          </Typography>
        </>
      )}

      <Box
        sx={{
          border: '1px solid',
          borderColor: 'text.secondary',
          backgroundColor: checkCorrect
            ? isCorrect
              ? alpha(theme.palette.success.main, 0.1)
              : alpha(theme.palette.error.main, 0.1)
            : 'background.default',
          p: 1.5,
          pb: 0.5,
          borderRadius: 1,
          my: 1,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            minHeight:
              question.type === 'mctf' && question.questionImageUrl
                ? imageSize.height * formatSize + 20
                : 'auto',
            overflowX: 'auto',
            scrollbarWidth: 'thin',
            overflowY: 'hidden',
          }}
        >
          {question.reuseContent && (
            <Box sx={{ position: 'relative' }}>
              {question.reuseContentImageUrl && (
                <ImageBox
                  src={question.reuseContentImageUrl}
                  alt={`Question ${question.index}`}
                  formatSize={formatSize}
                />
              )}
              <Typography variant="examText" gutterBottom>
                {RenderHTMLContent(question.reuseContent)}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              position: 'relative',
              minHeight:
                question.type === 'mctf' && question.questionImageUrl
                  ? imageSize.height * formatSize + 20
                  : 'auto',
            }}
          >
            {question.questionImageUrl && (
              <Box
                component="img"
                src={question.questionImageUrl}
                alt={`Question ${question.index}`}
                onLoad={handleImageLoad}
                sx={{
                  float: 'right',
                  // marginLeft: 2,
                  // marginBottom: 2,
                  width: imageSize.width * formatSize + 'px',
                  height: 'auto',
                  maxWidth: isMobile ? '100%' : '75%',
                }}
              />
            )}
            <Typography variant="examText">
              <strong>Câu {question.index}:</strong>{' '}
              {RenderHTMLContent(question.question)}
            </Typography>
          </Box>

          {renderQuestionType()}
          <Box
            display={
              (!editable || checkCorrect) && question.explain ? 'block' : 'none'
            }
            // sx={{
            //   overflowX: 'auto',
            //   // Change scrollbar style smaller
            //   scrollbarWidth: 'thin',
            //   overflowY: 'hidden',
            // }}
          >
            {question.explainImageUrl && (
              <ImageBox
                src={question.explainImageUrl}
                alt={`Explanation for question ${question.index}`}
                formatSize={formatSize}
                sx={{ float: 'left' }}
              />
            )}
            <Typography variant="examText" gutterBottom>
              {RenderHTMLContent2(question.explain)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(QuestionComponent);
