import { Box, Container, Divider, Typography, useTheme } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { fetchAllStudents } from '../../api'; // Add this function in your API file to fetch all students
import LoadingDisplay from './Loading';
import ErrorDisplay from './Error';

interface Student {
  _id: string;
  name: string;
  email: string;
  username: string;
  role: string;
  school: string;
  createdAt: string;
}

const CheckUserPage: FC = () => {
  const theme = useTheme();
  const [students, setStudents] = useState<Student[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const StyledTypography = ({ children, ...props }: any) => (
    <Typography
      textAlign="center"
      fontWeight={600}
      {...props}
      sx={{
        background: `linear-gradient(to right, ${theme.palette.prize.first}, #ff7e5f)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        flexWrap: 'wrap',
        ...props.sx,
      }}
    >
      {children}
    </Typography>
  );

  const columnProps = [
    {
      name: 'Tên',
      width: '20%',
      minWidth: 200,
      maxWidth: '20%',
      render: (student: Student) => student.name,
    },
    {
      name: 'Email',
      width: '30%',
      minWidth: 400,
      maxWidth: '30%',
      render: (student: Student) => student.email,
    },
    {
      name: 'Username',
      width: '30%',
      minWidth: 300,
      maxWidth: '30%',
      render: (student: Student) => student.name,
    },
    {
      name: 'Vai trò',
      width: '10%',
      minWidth: 100,
      maxWidth: '10%',
      render: (student: Student) => student.role,
    },
    {
      name: 'Trường học',
      width: '20%',
      minWidth: 200,
      maxWidth: '20%',
      render: (student: Student) => student.school,
    },
    {
      name: 'Ngày tham gia',
      width: '30%',
      minWidth: 200,
      maxWidth: '30%',
      render: (student: Student) =>
        new Date(student.createdAt).toLocaleDateString(),
    },
  ];

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const res = await fetchAllStudents();
        setStudents(res.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError('Đã xảy ra lỗi. Vui lòng thử lại sau.');
      }
    };
    fetchStudents();
  }, []);

  if (loading) {
    return <LoadingDisplay loadingMessage="Đang tải danh sách sinh viên..." />;
  }

  if (error) {
    return <ErrorDisplay errorMessage="Đã xảy ra lỗi. Vui lòng thử lại sau." />;
  }

  return (
    <Container>
      <Typography variant="h3" textAlign="center">
        Danh sách người dùng
      </Typography>
      {students?.length === 0 ? (
        <Typography variant="h6" textAlign="center">
          Không có dữ liệu
        </Typography>
      ) : (
        <>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            minHeight="50vh"
            width="100%"
            maxWidth="100%"
            sx={{
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                height: '8px',
              },
              maxHeight: '50vh',
            }}
          >
            <Box width="100%">
              {/* Header Columns */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                px={1}
                py={1}
                mt={1}
              >
                {columnProps.map((col) => (
                  <StyledTypography
                    key={col.name}
                    textAlign="center"
                    fontWeight={600}
                    sx={{
                      maxWidth: col.maxWidth,
                      width: col.width,
                      minWidth: col.minWidth,
                    }}
                  >
                    {col.name}
                  </StyledTypography>
                ))}
              </Box>
              <Divider
                sx={{
                  backgroundColor: 'text.secondary',
                  opacity: 1,
                  width: '100%',
                  minWidth: 1400,
                }}
              />
            </Box>

            {/* Rows */}
            {students?.map((student) => (
              <Box key={student._id} width="100%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  p={1.5}
                  sx={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  {columnProps.map((col) => (
                    <Typography
                      key={col.name}
                      textAlign="center"
                      fontWeight={500}
                      sx={{
                        maxWidth: col.maxWidth,
                        width: col.width,
                        minWidth: col.minWidth,
                        display: '-webkit-box', // Required for limiting lines of text
                        WebkitLineClamp: 1, // Limits to 2 lines; you can adjust this number
                        WebkitBoxOrient: 'vertical', // Required for multiline ellipsis
                        overflow: 'hidden', // Hides the overflowing text
                        textOverflow: 'ellipsis', // Adds '...' at the end of the truncated text
                      }}
                    >
                      {col.render(student)}
                    </Typography>
                  ))}
                </Box>
                <Divider
                  sx={{
                    backgroundColor: 'text.secondary',
                    opacity: 0.3,
                    minWidth: 1400,
                  }}
                />
              </Box>
            ))}
          </Box>
        </>
      )}
    </Container>
  );
};

export default CheckUserPage;
