import { ButtonProps } from '@mui/material';
import { Theme } from '@mui/material';

import Digital from '../assets/Fonts/digital.ttf';
import ArialRounded from '../assets/Fonts/ARLRDBD.ttf';
import SegoeUISemiBold from '../assets/Fonts/SegoeUI-SemiBold.ttf';
import ArialUnicodeMS from '../assets/Fonts/ArialUnicodeMS.ttf';
import _SegoeUIBold from '../assets/Fonts/Segoe-UI-Bold.ttf';
import _SegoeUINormal from '../assets/Fonts/SVN-Segoe-UI.ttf';
import HandWriting from '../assets/Fonts/Merienda-VariableFont_wght.ttf';
import Signature from '../assets/Fonts/BrothersideSignature-w13o6.otf';
import OswaldSemiBold from '../assets/Fonts/Oswald-SemiBold.ttf';
import _MontserratBold from '../assets/Fonts/Montserrat-Bold.ttf';
import { alpha } from '@mui/material/styles';
import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Arial, Quicksand, sans-serif'; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font
const EXAM_FONT = 'Times New Roman, serif';
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    gradient: true;
    gradient2: true;
    gradient3: true;
  }
}
//Add new variant examText to Typography

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    examText: true;
  }
}

const components = {
  // Add new variant examText to Typography
  MuiTypography: {
    styleOverrides: {
      root: {
        '&.MuiTypography-examText': {
          fontFamily: EXAM_FONT,
          // fontSize: pxToRem(24),
          // ...responsiveFontSizes({ sm: 22, md: 22, lg: 22 }),
        },
      },
    },
  },

  // Override table border color, which have tag like 'table', 'th', 'td'
  // MuiTable: {
  //   styleOverrides: {
  //     root: {
  //       border: '1px solid rgba(224, 224, 224, 1)',
  //     },
  //   },
  // },

  // Override default props of TextField component which fieldset border color is text.secondary
  // '& .MuiOutlinedInput-root': {
  //   '& fieldset': {
  //     borderColor: 'text.secondary', // Border color
  //   },
  // },
  // MuiTextField: {
  //   defaultProps: {
  //     variant: 'standard' as 'standard', // No need for type assertion
  //   },
  //   styleOverrides: {
  //     root: {
  //       // Targeting standard input directly since there's no fieldset in 'standard' variant
  //       '& .MuiInput-root': {
  //         '&:before': {
  //           borderBottomColor: 'text.secondary', // Set the underline color when not focused
  //         },
  //         '&:hover:before': {
  //           borderBottomColor: 'text.secondary', // Maintain underline color on hover
  //         },
  //         '&.Mui-focused:before': {
  //           borderBottomColor: 'text.secondary', // Set underline color when focused
  //         },
  //       },
  //     },
  //   },
  // },

  MuiCssBaseline: {
    styleOverrides: `
    
      @font-face {
        font-family: 'Digital';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${Digital}) format('truetype');
      }
      @font-face {
        font-family: 'OswaldSemiBold';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${OswaldSemiBold}) format('truetype');
      }
      @font-face {
        font-family: 'ArialRounded';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${ArialRounded}) format('truetype');
      }
      @font-face {
        font-family: 'SegoeUISemiBold';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${SegoeUISemiBold}) format('truetype');
      }
      @font-face {
        font-family: '_SegoeUIBold';
        font-style: normal;
        font-display: swap;
        font-weight: 800;
        src: url(${_SegoeUIBold}) format('truetype');
      }
      @font-face {
        font-family: '_MontserratBold';
        font-style: normal;
        font-display: swap;
        font-weight: 800;
        src: url(${_MontserratBold}) format('truetype');
      }
      @font-face {
        font-family: '_SegoeUINormal';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${_SegoeUINormal}) format('truetype');
      }
      @font-face {
        font-family: 'ArialUnicodeMS';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${ArialUnicodeMS}) format('truetype');
      }
      @font-face {
        font-family: 'HandWriting';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${HandWriting}) format('truetype');
      }
      @font-face {
        font-family: 'Signature';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${Signature}) format('opentype');
      }

      /* width */
      ::-webkit-scrollbar {
        width: 8px;
        border-radius: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #aaaaaa;
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      /* table, th, td border color to primary color */
      table, th, td {
        border: 1px solid #aaaaaa;
      }
    `,
  },
};

export default components;
