import React, { useState, useEffect, useRef } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import Course, { CourseProps } from './Course'; // Import the Course component
import useAuth from '../../hooks/useAuth';
import CreateCourseForm from './CreateCourseForm';
import {
  fetchAllCourses,
  fetchAllCoursesByTeacherId,
  getTop20UpcomingExams,
} from '../../api'; // Import the fetchAllCourses function
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/index';
import { setCourses } from '../../redux/slices/courseSlice';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import ErrorDisplay from './Error';
import LoadingDisplay from './Loading';
import CourseHeader from './CourseHeader';
import paths from '../../constants/paths';
import NotFoundPage from '../Error/NotFoundPage';
import { Exam } from '../Exams/type';
import ExamComponent from './Exam';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import useResponsive from '../../hooks/useResponsive';
import SubcriptionsPage from './SubcriptionsPage';
import HistoryPage from './HistoryPage';
import { StudentInfo } from '../../model/Student';
import DialogForm from '../Courses/DialogForm';
import ExamTabs from '../Exams';

interface HomePageProps {
  userInput?: StudentInfo | null;
}

const HomePage: React.FC<HomePageProps> = ({ userInput }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const courses = useSelector((state: RootState) => state.course.courses);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState<string>('all');
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [exams, setExams] = useState<Exam[]>([]);
  const [filteredExams, setFilteredExams] = useState<Exam[]>([]);
  // const sliderRef = useRef<HTMLDivElement>(null); // Reference to the slider container
  const [scrollPosition, setScrollPosition] = useState(0);
  const isMobile = useResponsive('down', 'md');
  const boxRef = useRef<HTMLDivElement>(null);
  const [profileUser, setProfileUser] = useState<StudentInfo | null>(
    userInput || null
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [dialogItemType, setDialogItemType] = useState<'subfolder' | 'other'>(
    'other'
  );
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogUrl, setDialogUrl] = useState('');
  const [dialogMaxWidth, setDialogMaxWidth] = useState<
    'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  >('xl');

  const [dialogContent, setDialogContent] = useState<React.ReactNode | null>(
    null
  );

  useEffect(() => {
    if (userInput) {
      setProfileUser(userInput);
    } else {
      setProfileUser(user);
    }
  }, [userInput, user]);

  const [boxWidth, setBoxWidth] = useState(1000);

  useEffect(() => {
    const box = boxRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect) {
          // Update width when size changes
          setBoxWidth(entry.contentRect.width);
        }
      }
    });

    if (box) {
      resizeObserver.observe(box);
    }

    // Cleanup observer on unmount
    return () => {
      if (box) {
        resizeObserver.unobserve(box);
      }
    };
  }, []);

  // useEffect(() => {
  //   const box = boxRef.current;

  //   if (box) {
  //     setBoxWidth(box?.offsetWidth); // Initialize width directly
  //   }
  // }, []);

  const isHomePage = location.pathname === paths.HOME;

  const isProfilePage = useMatch(paths.PROFILE) !== null;

  const isTeacher =
    !!user &&
    (user.role === 'admin' || user.role === 'teacher') &&
    !isProfilePage;

  // Check if path is /mycourse then filter courses by user id
  // useEffect(() => {
  //   setLoading(true);

  //   setTimeout(() => {
  //     if (isTeacher && profileUser.role === 'teacher') {
  //       setFilteredCourses(
  //         courses.filter((course) => course.createdBy._id === profileUser._id)
  //       );
  //     } else {
  //       setFilteredCourses(courses);
  //     }
  //     setLoading(false); // Set loading to false after the delay
  //   }, 500); // Ensure loading lasts for at least 0.5 seconds
  // }, [courses, isTeacher, profileUser]);

  useEffect(() => {
    setLoading(true);
    const loadCourses = async () => {
      if (location.pathname !== paths.HOME && !profileUser) {
        return;
      }
      try {
        let data;
        if (location.pathname === paths.HOME) {
          data = await fetchAllCourses();
        } else {
          data = await fetchAllCoursesByTeacherId(
            profileUser?._id || user?._id || ''
          );
        }

        if (location.pathname === paths.HOME) {
          data = data.filter((course: CourseProps) =>
            course.isPrivate === false ? course : null
          );
        } else {
          if (
            profileUser &&
            (profileUser.role === 'teacher' ||
              (profileUser.role === 'admin' && isProfilePage))
          ) {
            data = data.filter((course: CourseProps) =>
              course.createdBy._id === profileUser._id ? course : null
            );
          }
        }

        dispatch(setCourses(data)); // Dispatch setCourses action
        setFilteredCourses(data); // Set filtered courses to all courses initially

        const res = await getTop20UpcomingExams();
        const examData = res.data;
        setExams(examData);
        setFilteredExams(examData);
      } catch (err) {
        console.error('Error fetching courses:', err);
        setError('Failed to load courses');
      } finally {
        setLoading(false);
      }
    };

    loadCourses();
  }, [dispatch, location.pathname, profileUser]);

  const handleCreateCourse = () => {
    setShowCreateForm(true);
  };

  const handleCloseForm = () => {
    setShowCreateForm(false);
  };

  const handleOpenDialog = (id: string) => {
    setOpenDialog(true);
    setDialogContent(
      <ExamTabs
        examId={id}
        closeDialog={handleCloseDialog}
        isTeacher={isTeacher}
      />
    );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter); // Update the filter state
    setLoading(true); // Set loading to true immediately

    setTimeout(() => {
      let filteredCourse;
      let filteredExam;
      if (newFilter === 'all') {
        filteredCourse = courses; // Show all courses
        filteredExam = exams;
      } else {
        // Filter by subject
        filteredCourse = courses.filter((course) =>
          course.subject === newFilter ? course : null
        );
        filteredExam = exams.filter((exam) => exam.subject === newFilter);
      }

      filteredCourse && setFilteredCourses(filteredCourse); // Update the filtered courses
      filteredExam && setFilteredExams(filteredExam);
      setLoading(false); // Set loading to false after the delay
    }, 500); // Ensure loading lasts for at least 0.5 seconds
  };

  return (
    <Container maxWidth={false}>
      <Box sx={{ position: 'relative', mb: 4, mt: 2 }} ref={boxRef}>
        <CourseHeader
          onAddCourseClick={handleCreateCourse}
          onFilterChange={handleFilterChange}
          isTeacher={isTeacher}
          filter={filter}
          isHomePage={isHomePage}
        />

        <CreateCourseForm onClose={handleCloseForm} open={showCreateForm} />

        {loading && (
          <LoadingDisplay loadingMessage="Đang tải chi tiết khóa học..." />
        )}

        {error && (
          <ErrorDisplay errorMessage="Đã xảy ra lỗi. Vui lòng thử lại sau." />
        )}

        {!loading && !error && (
          <>
            {isHomePage && (
              <Box>
                {!isMobile && <SubcriptionsPage filter={filter} />}

                <Typography variant="h3" textAlign="center" mb={1}>
                  Đề thi nổi bật
                </Typography>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  {isMobile ||
                  filteredExams.length < Math.floor(boxWidth / 240) - 1 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        overflowX: 'auto',
                        scrollBehavior: 'smooth',
                        whiteSpace: 'nowrap',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                      }}
                    >
                      {filteredExams.map((exam) => (
                        <ExamComponent
                          key={exam.examId}
                          exam={exam}
                          handleOpenDialog={handleOpenDialog}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Swiper
                      // Calculate the number of slides to show based on screen width / 250
                      slidesPerView={Math.min(
                        Math.floor(boxWidth / 240),
                        filteredExams.length
                      )}
                      spaceBetween={0}
                    >
                      {filteredExams.map((exam) => (
                        <SwiperSlide key={exam.examId}>
                          <ExamComponent
                            exam={exam}
                            handleOpenDialog={handleOpenDialog}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </Box>
                {/* <HistoryPage filter={filter} /> */}
              </Box>
            )}
            {/* {user && <SubcriptionsPage />} */}
            {isHomePage && (
              <Typography variant="h3" textAlign="center" mt={2} mb={1}>
                Khoá học nổi bật
              </Typography>
            )}
            {isProfilePage && (
              <Typography variant="h3" textAlign="center" mb={1}>
                Khoá học nổi bật
              </Typography>
            )}
            <Grid container spacing={3} mt={-4}>
              {filteredCourses.map((course) => (
                <Grid
                  item
                  xs={12} // Full width on extra small screens
                  sm={6} // Half width on small screens and above
                  md={4} // One-third width on medium screens and above (adjust as needed)
                  lg={4} // One-fourth width on large screens and above (adjust as needed)
                  xl={2}
                  key={course.courseId}
                >
                  <Course
                    courseId={course.courseId}
                    image={course.image}
                    title={course.title}
                    description={course.description}
                    favorites={course.favorites}
                    members={course.members}
                    exams={course.exams}
                    videos={course.videos}
                    pdfs={course.pdfs}
                    createdAt={course.createdAt}
                    lastUpdatedAt={course.lastUpdatedAt}
                    createdBy={course.createdBy}
                    price={course.price}
                    contactLink={course.contactLink}
                    isTeacher={isTeacher}
                    rates={course.rates}
                    items={course.items} // Add missing items property
                    learningObjectives={course.learningObjectives} // Add missing learningObjectives property
                    subject={course.subject}
                    isPrivate={course.isPrivate}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Box>
      <DialogForm
        open={openDialog}
        onClose={handleCloseDialog}
        title={dialogTitle}
        url={dialogUrl}
        content={dialogContent}
        maxWidth={'xl'}
        type={'other'}
      />
    </Container>
  );
};

export default HomePage;
