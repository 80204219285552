import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface NotFoundPageProps {
  message?: string; // Optional props
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ message }) => {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        {/* <ErrorOutlineIcon
          sx={{ fontSize: 80, color: 'error.main', marginBottom: 2 }}
        /> */}
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          404 - Không tìm thấy trang
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          {message ||
            'Xin lỗi, trang bạn đang tìm không tồn tại hoặc đã được di chuyển.'}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/')}
          sx={{ marginBottom: 2 }}
        >
          Về trang chủ
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate(-1)}
        >
          Quay lại
        </Button>
      </Box>
    </Container>
  );
};

export default NotFoundPage;
