import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ThemeProvider from './theme';
import store from './redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MathJaxContext } from 'better-react-mathjax';
import './index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const mathJaxConfig = {
  tex2jax: {
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
    ], // Use $...$ or \( ... \) for inline math
    displayMath: [
      ['$$', '$$'],
      ['\\[', '\\]'],
    ], // Use $$...$$ or \[ ... \] for display math (block mode)
    // not add space after comma when display math
    // processEscapes: true,
  },
  options: {
    processHtmlClass: 'mathjax', // Only process elements with this class
    enableMenu: false,
  },
  chtml: {
    styles: {
      'mjx-mn, mjx-mi, mjx-mo, mjx-mtext': {
        fontWeight: 'normal', // Ensure all MathJax text is not bold
      },
      'mjx-mn': {
        'margin-right': '0', // Prevent space after numbers
      },
      'mjx-punct': {
        'margin-right': '0', // Prevent space after punctuation like commas
      },
    },
  },
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId="25091186844-6qht8oju8s3h9a7nvsb97njfb0qivrga.apps.googleusercontent.com">
      <MathJaxContext config={mathJaxConfig}>
        <Provider store={store}>
          <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <App />
            </LocalizationProvider>
          </ThemeProvider>
        </Provider>
      </MathJaxContext>
    </GoogleOAuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
