import { Box, Container, Stack, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SchoolIcon from '@mui/icons-material/School';
import CloseIcon from '@mui/icons-material/Close';
import InfoItem from '../Courses/CourseInfoItem';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NotFoundPage from '../Error/NotFoundPage';
import { StudentInfo } from '../../model/Student';

interface MePageProps {
  userInput?: StudentInfo | null;
}

const MePage: FC<MePageProps> = ({ userInput }) => {
  const { user } = useAuth();

  const [profileUser, setProfileUser] = useState<StudentInfo | null>(
    userInput || null
  );

  useEffect(() => {
    if (userInput) {
      setProfileUser(userInput);
    } else {
      setProfileUser(user);
    }
  }, [userInput, user]);

  if (!profileUser) {
    return <NotFoundPage message="Vui lòng đăng nhập để truy cập trang" />;
  }
  return (
    <Container>
      <Typography
        variant="h3"
        sx={{ padding: (theme) => theme.spacing(1, 3) }}
        textAlign="center"
      >
        Thông tin
      </Typography>
      <Stack direction="column" alignItems="center" mb={0} mt={1}>
        <Box position="relative" color="secondary">
          <img
            src={profileUser?.avatar}
            alt="Avatar"
            style={{
              width: 80,
              height: 80,
              borderRadius: '50%',
            }}
            referrerPolicy="no-referrer"
          />
        </Box>

        <Box display="flex" alignItems="center" mt={2} flexDirection="column">
          <InfoItem
            icon={<MailOutlineIcon sx={{ fontSize: 18 }} />}
            value={profileUser?.email || 'Chưa cập nhật'}
          />
          <InfoItem
            icon={<PersonOutlinedIcon sx={{ fontSize: 18 }} />}
            value={profileUser?.name || 'Chưa cập nhật'}
          />
          <InfoItem
            icon={<SchoolIcon sx={{ fontSize: 18 }} />}
            value={profileUser?.school || 'Chưa cập nhật'}
          />
          <InfoItem
            icon={<ManageAccountsIcon sx={{ fontSize: 18 }} />}
            value={profileUser?.role || 'Chưa cập nhật'}
          />
        </Box>
      </Stack>
    </Container>
  );
};

export default MePage;
